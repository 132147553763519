<template>
  <div class="instagram-auth">
    <button @click="openAuthUrl" :disabled="!authUrl">Authorize Instagram</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useRoute } from 'vue-router';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const settings = useSettingsStore();
const { BACK_URL } = storeToRefs(settings);
const route = useRoute();

const authUrl = ref('');

const getAuthUrl = async () => {
  try {
    const calleeId = route.params.callee_id;
    const response = await axios.get(`${BACK_URL.value}/insta/instagram/auth_url?callee_id=${calleeId}`);
    authUrl.value = response.data.auth_url;
  } catch (error) {
    console.error('Error fetching Instagram auth URL:', error);
  }
};

const openAuthUrl = () => {
  if (authUrl.value) {
    window.open(authUrl.value, '_blank');
  }
};

onMounted(() => {
  getAuthUrl();
});
</script>

<style scoped>
.instagram-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #3897f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #2a6eb5;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #ccc;
}

a {
  margin-top: 10px;
  color: #3897f0;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}
</style>
