<template>
  <div class="instagram-post-container">
    <!-- <h3>Post to Instagram</h3> -->
    <div v-if="answerDetails" class="answer-details">
      <!-- <div class="answer-info">
        <p><strong>Question:</strong> {{ answerDetails.question_text }}</p>
        <p><strong>Answer:</strong> {{ answerDetails.answer_text }}</p>
        <p><strong>Created at:</strong> {{ formatDate(answerDetails.created_at) }}</p>
        <p><strong>Callee IG Username:</strong> {{ answerDetails.callee_ig_username }}</p>
      </div> -->
      <div v-if="isGenerating" class="generating-message">
        Generating Instagram post...
      </div>
      <div v-else-if="generatedPost" class="generated-post">
        <div class="generated-caption">
          <h3>Generated Caption:</h3>
          <p>{{ generatedPost.caption }}</p>
        </div>
        <div class="generated-image">
          <h3>Generated Image:</h3>
          <img :src="generatedPost.image_url" alt="Generated Instagram post image" />
        </div>
        <div class="post-to-instagram">
          <button @click="postToInstagram" :disabled="isPosting">
            {{ isPosting ? 'Posting...' : 'Post Now' }}
          </button>
        </div>
        <div class="post-to-instagram">
          <button @click="generatePost" :disabled="isGenerating">
            {{ isGenerating ? 'Rewriting...' : 'Rewrite Post' }}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="error" class="error-message">
      {{ error }}
    </div>
    <div v-else class="loading">
      Loading answer details...
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';

const props = defineProps({
  answerId: {
    type: String,
    required: true
  }
});

const settings = useSettingsStore();
const { BACK_URL } = storeToRefs(settings);

const answerDetails = ref(null);
const error = ref(null);
const isGenerating = ref(false);
const isPosting = ref(false);
const generatedPost = ref(null);

const getAnswerDetails = async () => {
  try {
    const response = await axios.get(`${BACK_URL.value}/answer/answer_getbyid/${props.answerId}`);
    answerDetails.value = response.data;
    generatePost(); // Automatically start generating the post
  } catch (err) {
    console.error('Error fetching answer details:', err);
    error.value = 'Failed to load answer details. Please try again.';
  }
};

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleString();
};

const generatePost = async () => {
  if (!answerDetails.value) return;

  isGenerating.value = true;
  generatedPost.value = null;

  try {
    const response = await axios.post(`${BACK_URL.value}/ai/answer_post_process`, {
      callee_name: answerDetails.value.callee_name,
      qa_of_focus: {
        question: answerDetails.value.question_text,
        answer: answerDetails.value.answer_text
      }
    });
    generatedPost.value = {
      caption: response.data.caption,
      image_url: response.data.image_url
    };
  } catch (err) {
    console.error('Error generating post:', err);
    error.value = 'Failed to generate post. Please try again.';
  } finally {
    isGenerating.value = false;
  }
};

const postToInstagram = async () => {
  if (!generatedPost.value || !answerDetails.value.callee_ig_username) return;

  isPosting.value = true;

  try {
    const response = await axios.post(`${BACK_URL.value}/insta/post_to_feed`, {
      username: answerDetails.value.callee_ig_username,
      caption: generatedPost.value.caption,
      image_url: generatedPost.value.image_url,
      answer_id: props.answerId,
      question_id: String(answerDetails.value.question_id)
    });
    alert('Successfully posted to Instagram!');
  } catch (err) {
    console.error('Error posting to Instagram:', err);
    error.value = 'Failed to post to Instagram. Please try again.';
  } finally {
    isPosting.value = false;
  }
};

onMounted(() => {
  getAnswerDetails();
});

watch(() => props.answerId, () => {
  getAnswerDetails();
});
</script>

<style scoped>
.post-to-instagram {
  margin-top: 1.2em;
}

.instagram-post-container {
  padding: 20px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  margin-top: 20px;
}

.answer-details {
  margin-top: 20px;
  text-align: left;
  width: 100%;
  max-width: 600px;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.loading, .generating-message {
  margin-top: 20px;
  font-style: italic;
}

.generated-post {
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
}

.generated-post img {
  width: 100%;
  height: auto;
}

button:disabled {
  background-color: #b2dffc;
  cursor: not-allowed;
}
</style>
